.App {
  text-align: center;
}

.App-logo {
    position: relative;
    z-index: 2; /* Place above the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle {
  mix-blend-mode: multiply;
  position: absolute;
  border-radius: 50%;
  transition: transform 0.5s ease-in-out;
}

.circleLeft {
  background-color: #7fffd4; /* Green */
  left:0px
}

.circleRight {
  background-color: #00bfff; /* Blue */
  right: 0px;
}

.leftLogoMove {
  animation: moveLeftCircles infinite 2s ease-in-out;
}

.rightLogoMove{
  animation: moveRightCircles infinite 2s ease-in-out;
}

@media (prefers-reduced-motion: no-preference) {
  .leftLogoMove {
    animation: moveLeftCircles infinite 2s ease-in-out;
  }
}

@keyframes moveLeftCircles {
  0%, 100% {
    transform: translateX(0px); /* Start and end in the center */
  }
  50% {
    transform: translateX(-26px); /* Move apart at the midpoint */
  }
}

@keyframes moveRightCircles {
  0%, 100% {
    transform: translateX(0px); /* Start and end in the center */
  }
  50% {
    transform: translateX(26px); /* Move apart at the midpoint */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
